<template>
	<div class="Evaluate" id="Evaluate">
		<div class="title-hang">
			<div class="fl">
				<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
			</div>
			<div class="zhuangshizb fl"></div>
			<div class="zhuangshizj fl">{{ projectData.project }}_运行评价</div>
			<div class="zhuangshiyb fl"></div>
			<div class="fr">
				<div class="fl">
					<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
				</div>
			</div>
		</div>
		<div class="conter">
			<div class="conter1 fl" style="margin-left: 0">
				<div class="title1">CEI指标</div>
				<div class="divcei" v-if="infoList.hasOwnProperty('V_TJ.CEI_LEVEL')">
					{{infoList.V_TJ.CEI_LEVEL}}
				</div>
				<div class="divcei" v-else>
					{{
					infoList.V_PJZB.WDX_1 >= 90
					? "A"
					: infoList.V_PJZB.WDX_1 < 90 && infoList.V_PJZB.WDX_1 >= 70
					? "B"
					: infoList.V_PJZB.WDX_1 < 70 && infoList.V_PJZB.WDX_1 >= 60
					? "C"
					: "D"
					}}

					{{
					infoList.V_TJ.ZKL1_KHB >= 90
					? "A"
					: infoList.V_TJ.ZKL1_KHB < 90 && infoList.V_TJ.ZKL1_KHB >= 70
					? "B"
					: infoList.V_TJ.ZKL1_KHB < 70 && infoList.V_TJ.ZKL1_KHB >= 60
					? "C"
					: "D"
					}}
					{{
					infoList.V_PJZB.JJX_R >= 90
					? "A"
					: infoList.V_PJZB.JJX_R < 90 && infoList.V_PJZB.JJX_R >= 70
					? "B"
					: infoList.V_PJZB.JJX_R < 70 && infoList.V_PJZB.JJX_R >= 60
					? "C"
					: "D"
					}}
				</div>
			</div>
			<div class="conter1 fl">
				<div class="title1">经济性指标（%）</div>
				<div class="divjjx" @click="toCompon(2, 'JJX_R', 'V_PJZB', 'JJX_R_WF','经济性指标')" @dblclick="
            Cclick(infoList.V_PJZB.JJX_R, 'JJX_R', 'V_PJZB', 'JJX_R_WF')
          ">
					<dv-decoration-9 style="width: 20vh; height: 20vh">{{
            infoList.V_PJZB.JJX_R
          }}</dv-decoration-9>
				</div>
			</div>
			<div class="conter2 fl">
				<div class="title1">自控率（%）</div>
				<div id="myChart" class="divzkl"></div>
			</div>
			<div class="conter2 fl">
				<div class="title1">稳定性指标（%）</div>
				<div class="divwdx">
					<div class="hang1">
						<div class="row1 fl"></div>
						<div class="row2 fl">1#热风炉</div>
						<div class="row2 fl">2#热风炉</div>
						<div class="row2 fl">3#热风炉</div>
						<div class="row2 fl">4#热风炉</div>
					</div>
					<div class="hang2">
						<div class="row1 fl">汇总</div>
						<div class="row3 fl"></div>
						<div class="row2 fl" @click="toCompon(2, 'WDX_1', 'V_PJZB', 'WDX_1_WF','1#稳定性指标0-100')"
							@dblclick="
                Cclick(infoList.V_PJZB.WDX_1, 'WDX_1', 'V_PJZB', 'WDX_1_WF')
              ">
							{{ infoList.V_PJZB.WDX_1 }}
						</div>
						<div class="row3 fl"></div>
						<div class="row2 fl" @click="toCompon(2, 'WDX_2', 'V_PJZB', 'WDX_2_WF','2#稳定性指标0-100')"
							@dblclick="
                Cclick(infoList.V_PJZB.WDX_2, 'WDX_2', 'V_PJZB', 'WDX_2_WF')
              ">
							{{ infoList.V_PJZB.WDX_2 }}
						</div>
						<div class="row3 fl"></div>
						<div class="row2 fl" @click="toCompon(2, 'WDX_3', 'V_PJZB', 'WDX_3_WF','3#稳定性指标0-100')"
							@dblclick="
                Cclick(infoList.V_PJZB.WDX_3, 'WDX_3', 'V_PJZB', 'WDX_3_WF')
              ">
							{{ infoList.V_PJZB.WDX_3 }}
						</div>
						<div class="row3 fl"></div>
						<div class="row2 fl" @click="toCompon(2, 'WDX_4', 'V_PJZB', 'WDX_4_WF','4#稳定性指标0-100')"
							@dblclick="
                Cclick(infoList.V_PJZB.WDX_4, 'WDX_4', 'V_PJZB', 'WDX_4_WF')
              ">
							{{ infoList.V_PJZB.WDX_4 }}
						</div>
					</div>
					<div class="hang3">
						<div class="row1 fl">煤气</div>
						<div class="row2 fl" @click="toCompon(2, 'MQBFB_1', 'V_PJZB', 'MQBFB_1_WF','1#煤气稳定性指标0-100')"
							@dblclick="
                Cclick(
                  infoList.V_PJZB.MQBFB_1,
                  'MQBFB_1',
                  'V_PJZB',
                  'MQBFB_1_WF'
                )
              ">
							{{ infoList.V_PJZB.MQBFB_1 }}
						</div>
						<div class="row2 fl" @click="toCompon(2, 'MQBFB_2', 'V_PJZB', 'MQBFB_2_WF','2#煤气稳定性指标0-100')"
							@dblclick="
                Cclick(
                  infoList.V_PJZB.MQBFB_2,
                  'MQBFB_2',
                  'V_PJZB',
                  'MQBFB_2_WF'
                )
              ">
							{{ infoList.V_PJZB.MQBFB_2 }}
						</div>
						<div class="row2 fl" @click="toCompon(2, 'MQBFB_3', 'V_PJZB', 'MQBFB_3_WF','3#煤气稳定性指标0-100')"
							@dblclick="
                Cclick(
                  infoList.V_PJZB.MQBFB_3,
                  'MQBFB_3',
                  'V_PJZB',
                  'MQBFB_3_WF'
                )
              ">
							{{ infoList.V_PJZB.MQBFB_3 }}
						</div>
						<div class="row2 fl" @click="toCompon(2, 'MQBFB_4', 'V_PJZB', 'MQBFB_4_WF','4#煤气稳定性指标0-100')"
							@dblclick="
                Cclick(
                  infoList.V_PJZB.MQBFB_4,
                  'MQBFB_4',
                  'V_PJZB',
                  'MQBFB_4_WF'
                )
              ">
							{{ infoList.V_PJZB.MQBFB_4 }}
						</div>
					</div>
					<div class="hang3">
						<div class="row1 fl">空气</div>
						<div class="row2 fl" @click="toCompon(2, 'KQBFB_1', 'V_PJZB', 'KQBFB_1_WF','1#空气稳定性指标0-100')"
							@dblclick="
                Cclick(
                  infoList.V_PJZB.KQBFB_1,
                  'KQBFB_1',
                  'V_PJZB',
                  'KQBFB_1_WF'
                )
              ">
							{{ infoList.V_PJZB.KQBFB_1 }}
						</div>
						<div class="row2 fl" @click="toCompon(2, 'KQBFB_2', 'V_PJZB', 'KQBFB_2_WF','2#空气稳定性指标0-100')"
							@dblclick="
                Cclick(
                  infoList.V_PJZB.KQBFB_2,
                  'KQBFB_2',
                  'V_PJZB',
                  'KQBFB_2_WF'
                )
              ">
							{{ infoList.V_PJZB.KQBFB_2 }}
						</div>
						<div class="row2 fl" @click="toCompon(2, 'KQBFB_3', 'V_PJZB', 'KQBFB_3_WF','3#空气稳定性指标0-100')"
							@dblclick="
                Cclick(
                  infoList.V_PJZB.KQBFB_3,
                  'KQBFB_3',
                  'V_PJZB',
                  'KQBFB_3_WF'
                )
              ">
							{{ infoList.V_PJZB.KQBFB_3 }}
						</div>
						<div class="row2 fl" @click="toCompon(2, 'KQBFB_4', 'V_PJZB', 'KQBFB_4_WF','4#空气稳定性指标0-100')"
							@dblclick="
                Cclick(
                  infoList.V_PJZB.KQBFB_4,
                  'KQBFB_4',
                  'V_PJZB',
                  'KQBFB_4_WF'
                )
              ">
							{{ infoList.V_PJZB.KQBFB_4 }}
						</div>
					</div>
					<div class="hang3">
						<div class="row1 fl">拱顶</div>
						<div class="row2 fl"
							@click="toCompon(2, 'GDWDBFB_1', 'V_PJZB', 'GDWDBFB_1_WF','1#拱顶温度稳定性指标0-100')" @dblclick="
                Cclick(
                  infoList.V_PJZB.GDWDBFB_1,
                  'GDWDBFB_1',
                  'V_PJZB',
                  'GDWDBFB_1_WF'
                )
              ">
							{{ infoList.V_PJZB.GDWDBFB_1 }}
						</div>
						<div class="row2 fl"
							@click="toCompon(2, 'GDWDBFB_2', 'V_PJZB', 'GDWDBFB_2_WF','2#拱顶温度稳定性指标0-100')" @dblclick="
                Cclick(
                  infoList.V_PJZB.GDWDBFB_2,
                  'GDWDBFB_2',
                  'V_PJZB',
                  'GDWDBFB_2_WF'
                )
              ">
							{{ infoList.V_PJZB.GDWDBFB_2 }}
						</div>
						<div class="row2 fl"
							@click="toCompon(2, 'GDWDBFB_3', 'V_PJZB', 'GDWDBFB_3_WF','3#拱顶温度稳定性指标0-100')" @dblclick="
                Cclick(
                  infoList.V_PJZB.GDWDBFB_3,
                  'GDWDBFB_3',
                  'V_PJZB',
                  'GDWDBFB_3_WF'
                )
              ">
							{{ infoList.V_PJZB.GDWDBFB_3 }}
						</div>
						<div class="row2 fl"
							@click="toCompon(2, 'GDWDBFB_4', 'V_PJZB', 'GDWDBFB_4_WF','4#拱顶温度稳定性指标0-100')" @dblclick="
                Cclick(
                  infoList.V_PJZB.GDWDBFB_4,
                  'GDWDBFB_4',
                  'V_PJZB',
                  'GDWDBFB_4_WF'
                )
              ">
							{{ infoList.V_PJZB.GDWDBFB_4 }}
						</div>
					</div>
					<div class="hang3">
						<div class="row1 fl">废气</div>
						<div class="row2 fl"
							@click="toCompon(2, 'FQWDBFB_1', 'V_PJZB', 'FQWDBFB_1_WF','1#废气温度稳定性指标0-100')" @dblclick="
                Cclick(
                  infoList.V_PJZB.FQWDBFB_1,
                  'FQWDBFB_1',
                  'V_PJZB',
                  'FQWDBFB_1_WF'
                )
              ">
							{{ infoList.V_PJZB.FQWDBFB_1 }}
						</div>
						<div class="row2 fl"
							@click="toCompon(2, 'FQWDBFB_2', 'V_PJZB', 'FQWDBFB_2_WF','2#废气温度稳定性指标0-100')" @dblclick="
                Cclick(
                  infoList.V_PJZB.FQWDBFB_2,
                  'FQWDBFB_2',
                  'V_PJZB',
                  'FQWDBFB_2_WF'
                )
              ">
							{{ infoList.V_PJZB.FQWDBFB_2 }}
						</div>
						<div class="row2 fl"
							@click="toCompon(2, 'FQWDBFB_3', 'V_PJZB', 'FQWDBFB_3_WF','3#废气温度稳定性指标0-100')" @dblclick="
                Cclick(
                  infoList.V_PJZB.FQWDBFB_3,
                  'FQWDBFB_3',
                  'V_PJZB',
                  'FQWDBFB_3_WF'
                )
              ">
							{{ infoList.V_PJZB.FQWDBFB_3 }}
						</div>
						<div class="row2 fl"
							@click="toCompon(2, 'FQWDBFB_4', 'V_PJZB', 'FQWDBFB_4_WF','4#废气温度稳定性指标0-100')" @dblclick="
                Cclick(
                  infoList.V_PJZB.FQWDBFB_4,
                  'FQWDBFB_4',
                  'V_PJZB',
                  'FQWDBFB_4_WF'
                )
              ">
							{{ infoList.V_PJZB.FQWDBFB_4 }}
						</div>
					</div>
					<div class="hang3">
						<div class="row1 fl">风温</div>
						<div class="row2 fl"
							@click="toCompon(2, 'SFWDBFB_1', 'V_PJZB', 'SFWDBFB_1_WF','1#送风温度稳定性指标0-100')" @dblclick="
                Cclick(
                  infoList.V_PJZB.SFWDBFB_1,
                  'SFWDBFB_1',
                  'V_PJZB',
                  'SFWDBFB_1_WF'
                )
              ">
							{{ infoList.V_PJZB.SFWDBFB_1 }}
						</div>
						<div class="row2 fl"
							@click="toCompon(2, 'SFWDBFB_2', 'V_PJZB', 'SFWDBFB_2_WF','2#送风温度稳定性指标0-100')" @dblclick="
                Cclick(
                  infoList.V_PJZB.SFWDBFB_2,
                  'SFWDBFB_2',
                  'V_PJZB',
                  'SFWDBFB_2_WF'
                )
              ">
							{{ infoList.V_PJZB.SFWDBFB_2 }}
						</div>
						<div class="row2 fl"
							@click="toCompon(2, 'SFWDBFB_3', 'V_PJZB', 'SFWDBFB_3_WF','3#送风温度稳定性指标0-100')" @dblclick="
                Cclick(
                  infoList.V_PJZB.SFWDBFB_3,
                  'SFWDBFB_3',
                  'V_PJZB',
                  'SFWDBFB_3'
                )
              ">
							{{ infoList.V_PJZB.SFWDBFB_3 }}
						</div>
						<div class="row2 fl"
							@click="toCompon(2, 'SFWDBFB_4', 'V_PJZB', 'SFWDBFB_4_WF','4#送风温度稳定性指标0-100')" @dblclick="
                Cclick(
                  infoList.V_PJZB.SFWDBFB_4,
                  'SFWDBFB_4',
                  'V_PJZB',
                  'SFWDBFB_4_WF'
                )
              ">
							{{ infoList.V_PJZB.SFWDBFB_4 }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="conter11">
			<div class="table1 fl">
				<div style="height: 6vh">
					<div class="el-contal1 fl">炉号/时段</div>
					<div class="el-contal2 fl">
						<div class="heightr">拱顶温度</div>
						<div class="heightr">
							<div class="fl heightw">最大值</div>
							<div class="fl heightw">平均值</div>
						</div>
					</div>
					<div class="el-contal3 fl">
						<div class="heightr1">废气温度(℃)</div>
						<div class="heightr1">最大值</div>
					</div>
				</div>

				<div class="div2">
					<div class="div2-1 fl">1#</div>
					<div class="div2-2 fl">
						<div class="div2-2-1">当前</div>
						<div class="div2-2-2">上次</div>
						<div class="div2-2-2">前次</div>
					</div>
					<div class="div2-3 fl">
						<div class="div2-3-1" @click="toCompon(2, 'DTGDB_B1', 'V_TJ1', 'DTYDB_B1_WF','1#本次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.DTGDB_B1,
                  'DTGDB_B1',
                  'V_TJ1',
                  'DTYDB_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.DTGDB_B1 }}
						</div>
						<div class="div2-3-2" @click="toCompon(2, 'DTGDS_B1', 'V_TJ1', 'DTYDS_B1_WF','1#上次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.DTGDS_B1,
                  'DTGDS_B1',
                  'V_TJ1',
                  'DTYDS_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.DTGDS_B1 }}
						</div>
						<div class="div2-3-2" @click="toCompon(2, 'DTGDQ_B1', 'V_TJ1', 'DTYDQ_B1_WF','1#前次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.DTGDQ_B1,
                  'DTGDQ_B1',
                  'V_TJ1',
                  'DTYDQ_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.DTGDQ_B1 }}
						</div>
					</div>
					<div class="div2-3 fl">
						<div class="div2-3-1" @click="toCompon(2, 'JTGDB_B1', 'V_TJ1', 'JTGDB_B1_WF','1#本次拱顶温度均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.JTGDB_B1,
                  'JTGDB_B1',
                  'V_TJ1',
                  'JTGDB_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.JTGDB_B1 }}
						</div>
						<div class="div2-3-2" @click="toCompon(2, 'JTGDS_B1', 'V_TJ1', 'JTGDS_B1_WF','1#上次拱顶温度均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.JTGDS_B1,
                  'JTGDS_B1',
                  'V_TJ1',
                  'JTGDS_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.JTGDS_B1 }}
						</div>
						<div class="div2-3-2" @click="toCompon(2, 'JTGDQ_B1', 'V_TJ1', 'JTGDQ_B1_WF','1#前次拱顶温度均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.JTGDQ_B1,
                  'JTGDQ_B1',
                  'V_TJ1',
                  'JTGDQ_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.JTGDQ_B1 }}
						</div>
					</div>
					<div class="div2-3 fl">
						<div class="div2-3-1" style="width: 5.8vw"
							@click="toCompon(2, 'DTYDB_B1', 'V_TJ1', 'DTYDB_B1_WF','1#本次废气温度大值')" @dblclick="
                Cclick(
                  infoList.V_TJ1.DTYDB_B1,
                  'DTYDB_B1',
                  'V_TJ1',
                  'DTYDB_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.DTYDB_B1 }}
						</div>
						<div class="div2-3-3" @click="toCompon(2, 'DTYDS_B1', 'V_TJ1', 'DTYDS_B1_WF','1#上次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.DTYDS_B1,
                  'DTYDS_B1',
                  'V_TJ1',
                  'DTYDS_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.DTYDS_B1 }}
						</div>
						<div class="div2-3-3" @click="toCompon(2, 'DTYDQ_B1', 'V_TJ1', 'DTYDQ_B1_WF','1#前次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.DTYDQ_B1,
                  'DTYDQ_B1',
                  'V_TJ1',
                  'DTYDQ_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.DTYDQ_B1 }}
						</div>
					</div>
				</div>

				<div class="div2">
					<div class="div2-1 fl">2#</div>
					<div class="div2-2 fl">
						<div class="div2-2-1">当前</div>
						<div class="div2-2-2">上次</div>
						<div class="div2-2-2">前次</div>
					</div>
					<div class="div2-3 fl">
						<div class="div2-3-1" @click="toCompon(2, 'DTGDB_B2', 'V_TJ2', 'DTYDB_B2_WF','2#本次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.DTGDB_B2,
                  'DTGDB_B2',
                  'V_TJ2',
                  'DTYDB_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.DTGDB_B2 }}
						</div>
						<div class="div2-3-2" @click="toCompon(2, 'DTGDS_B2', 'V_TJ2', 'DTYDS_B2_WF','2#上次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.DTGDS_B2,
                  'DTGDS_B2',
                  'V_TJ2',
                  'DTYDS_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.DTGDS_B2 }}
						</div>
						<div class="div2-3-2" @click="toCompon(2, 'DTGDQ_B2', 'V_TJ2', 'DTGDQ_B2_WF','2#前次拱顶温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.DTGDQ_B2,
                  'DTGDQ_B2',
                  'V_TJ2',
                  'DTGDQ_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.DTGDQ_B2 }}
						</div>
					</div>
					<div class="div2-3 fl">
						<div class="div2-3-1" @click="toCompon(2, 'JTGDB_B2', 'V_TJ2', 'JTGDB_B2_WF','2#本次拱顶温度均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.JTGDB_B2,
                  'JTGDB_B2',
                  'V_TJ2',
                  'JTGDB_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.JTGDB_B2 }}
						</div>
						<div class="div2-3-2" @click="toCompon(2, 'JTGDS_B2', 'V_TJ2', 'JTGDS_B2_WF','2#上次拱顶温度均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.JTGDS_B2,
                  'JTGDS_B2',
                  'V_TJ2',
                  'JTGDS_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.JTGDS_B2 }}
						</div>
						<div class="div2-3-2" @click="toCompon(2, 'JTGDQ_B2', 'V_TJ2', 'JTGDQ_B2_WF','2#前次拱顶温度均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.JTGDQ_B2,
                  'JTGDQ_B2',
                  'V_TJ2',
                  'JTGDQ_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.JTGDQ_B2 }}
						</div>
					</div>
					<div class="div2-3 fl">
						<div class="div2-3-1" style="width: 5.8vw"
							@click="toCompon(2, 'DTYDB_B2', 'V_TJ2', 'DTYDB_B2_WF','2#本次废气温度大值')" @dblclick="
                Cclick(
                  infoList.V_TJ2.DTYDB_B2,
                  'DTYDB_B2',
                  'V_TJ2',
                  'DTYDB_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.DTYDB_B2 }}
						</div>

						<div class="div2-3-3" @click="toCompon(2, 'DTYDS_B2', 'V_TJ2', 'DTYDS_B2_WF','2#上次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.DTYDS_B2,
                  'DTYDS_B2',
                  'V_TJ2',
                  'DTYDS_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.DTYDS_B2 }}
						</div>
						<div class="div2-3-3" @click="toCompon(2, 'DTYDQ_B2', 'V_TJ2', 'DTYDQ_B2_WF','2#前次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.DTYDQ_B2,
                  'DTYDQ_B2',
                  'V_TJ2',
                  'DTYDQ_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.DTYDQ_B2 }}
						</div>
					</div>
				</div>
				<div class="div2">
					<div class="div2-1 fl">3#</div>
					<div class="div2-2 fl">
						<div class="div2-2-1">当前</div>
						<div class="div2-2-2">上次</div>
						<div class="div2-2-2">前次</div>
					</div>
					<div class="div2-3 fl">
						<div class="div2-3-1" @click="toCompon(2, 'DTGDB_B3', 'V_TJ3', 'DTYDB_B3_WF','3#本次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.DTGDB_B3,
                  'DTGDB_B3',
                  'V_TJ3',
                  'DTYDB_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.DTGDB_B3 }}
						</div>
						<div class="div2-3-2" @click="toCompon(2, 'DTGDS_B3', 'V_TJ3', 'DTYDS_B3_WF','3#上次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.DTGDS_B3,
                  'DTGDS_B3',
                  'V_TJ3',
                  'DTYDS_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.DTGDS_B3 }}
						</div>
						<div class="div2-3-2" @click="toCompon(2, 'DTGDQ_B3', 'V_TJ3', 'DTGDQ_B3_WF','3#前次拱顶温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.DTGDQ_B3,
                  'DTGDQ_B3',
                  'V_TJ3',
                  'DTGDQ_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.DTGDQ_B3 }}
						</div>
					</div>
					<div class="div2-3 fl">
						<div class="div2-3-1" @click="toCompon(2, 'JTGDB_B3', 'V_TJ3', 'JTGDB_B3_WF','3#本次拱顶温度均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.JTGDB_B3,
                  'JTGDB_B3',
                  'V_TJ3',
                  'JTGDB_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.JTGDB_B3 }}
						</div>
						<div class="div2-3-2" @click="toCompon(2, 'JTGDS_B3', 'V_TJ3', 'JTGDS_B3_WF','3#上次拱顶温度均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.JTGDS_B3,
                  'JTGDS_B3',
                  'V_TJ3',
                  'JTGDS_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.JTGDS_B3 }}
						</div>
						<div class="div2-3-2" @click="toCompon(2, 'JTGDQ_B3', 'V_TJ3', 'JTGDQ_B3_WF','3#前次拱顶温度均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.JTGDQ_B3,
                  'JTGDQ_B3',
                  'V_TJ3',
                  'JTGDQ_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.JTGDQ_B3 }}
						</div>
					</div>
					<div class="div2-3 fl">
						<div class="div2-3-1" style="width: 5.8vw"
							@click="toCompon(2, 'DTYDB_B3', 'V_TJ3', 'DTYDB_B3_WF','3#本次废气温度大值')" @dblclick="
                Cclick(
                  infoList.V_TJ3.DTYDB_B3,
                  'DTYDB_B3',
                  'V_TJ3',
                  'DTYDB_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.DTYDB_B3 }}
						</div>

						<div class="div2-3-3" @click="toCompon(2, 'DTYDS_B3', 'V_TJ3', 'DTYDS_B3_WF','3#上次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.DTYDS_B3,
                  'DTYDS_B3',
                  'V_TJ3',
                  'DTYDS_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.DTYDS_B3 }}
						</div>
						<div class="div2-3-3" @click="toCompon(2, 'DTYDQ_B3', 'V_TJ3', 'DTYDQ_B3_WF','3#前次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.DTYDQ_B3,
                  'DTYDQ_B3',
                  'V_TJ3',
                  'DTYDQ_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.DTYDQ_B3 }}
						</div>
					</div>
				</div>
				<div class="div2">
					<div class="div2-1 fl">4#</div>
					<div class="div2-2 fl">
						<div class="div2-2-1">当前</div>
						<div class="div2-2-2">上次</div>
						<div class="div2-2-2">前次</div>
					</div>
					<div class="div2-3 fl">
						<div class="div2-3-1" @click="toCompon(2, 'DTGDB_B4', 'V_TJ4', 'DTYDB_B4_WF','4#本次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.DTGDB_B4,
                  'DTGDB_B4',
                  'V_TJ4',
                  'DTYDB_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.DTGDB_B4 }}
						</div>
						<div class="div2-3-2" @click="toCompon(2, 'DTGDS_B4', 'V_TJ4', 'DTYDS_B4_WF','4#上次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.DTGDS_B4,
                  'DTGDS_B4',
                  'V_TJ4',
                  'DTYDS_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.DTGDS_B4 }}
						</div>
						<div class="div2-3-2" @click="toCompon(2, 'DTGDQ_B4', 'V_TJ4', 'DTGDQ_B4_WF','4#前次拱顶温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.DTGDQ_B4,
                  'DTGDQ_B4',
                  'V_TJ4',
                  'DTGDQ_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.DTGDQ_B4 }}
						</div>
					</div>
					<div class="div2-3 fl">
						<div class="div2-3-1" @click="toCompon(2, 'JTGDB_B4', 'V_TJ4', 'JTGDB_B4_WF','4#本次拱顶温度均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.JTGDB_B4,
                  'JTGDB_B4',
                  'V_TJ4',
                  'JTGDB_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.JTGDB_B4 }}
						</div>
						<div class="div2-3-2" @click="toCompon(2, 'JTGDS_B4', 'V_TJ4', 'JTGDS_B4_WF','4#上次拱顶温度均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.JTGDS_B4,
                  'JTGDS_B4',
                  'V_TJ4',
                  'JTGDS_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.JTGDS_B4 }}
						</div>
						<div class="div2-3-2" @click="toCompon(2, 'JTGDQ_B4', 'V_TJ4', 'JTGDQ_B4_WF','4#前次拱顶温度均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.JTGDQ_B4,
                  'JTGDQ_B4',
                  'V_TJ4',
                  'JTGDQ_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.JTGDQ_B4 }}
						</div>
					</div>
					<div class="div2-3 fl">
						<div class="div2-3-1" style="width: 5.8vw"
							@click="toCompon(2, 'DTYDB_B4', 'V_TJ4', 'DTYDB_B4_WF','4#本次废气温度大值')" @dblclick="
                Cclick(
                  infoList.V_TJ4.DTYDB_B4,
                  'DTYDB_B4',
                  'V_TJ4',
                  'DTYDB_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.DTYDB_B4 }}
						</div>

						<div class="div2-3-3" @click="toCompon(2, 'DTYDS_B4', 'V_TJ4', 'DTYDS_B4_WF','4#上次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.DTYDS_B4,
                  'DTYDS_B4',
                  'V_TJ4',
                  'DTYDS_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.DTYDS_B4 }}
						</div>
						<div class="div2-3-3" @click="toCompon(2, 'DTYDQ_B4', 'V_TJ4', 'DTYDQ_B4_WF','4#前次废气温度大值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.DTYDQ_B4,
                  'DTYDQ_B4',
                  'V_TJ4',
                  'DTYDQ_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.DTYDQ_B4 }}
						</div>
					</div>
				</div>
			</div>
			<div class="table2 fl">
				<div style="height: 6vh">
					<div class="el-contal4 fl">
						<div class="heightr2">煤气压力（Kpa）</div>
						<div class="heightr2">平均值</div>
						<div class="heightr3" @click="toCompon(2, 'JPMQB_B1', 'V_TJ1', 'JPMQB_B1_WF','1#本次煤气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.JPMQB_B1,
                  'JPMQB_B1',
                  'V_TJ1',
                  'JPMQB_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.JPMQB_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPMQS_B1', 'V_TJ1', 'JPMQS_B1_WF','1#上次煤气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.JPMQS_B1,
                  'JPMQS_B1',
                  'V_TJ1',
                  'JPMQS_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.JPMQS_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPMQQ_B1', 'V_TJ1', 'JPMQQ_B1_WF','1#前次煤气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.JPMQQ_B1,
                  'JPMQQ_B1',
                  'V_TJ1',
                  'JPMQQ_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.JPMQQ_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPMQB_B2', 'V_TJ2', 'JPMQB_B2_WF','2#本次煤气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.JPMQB_B2,
                  'JPMQB_B2',
                  'V_TJ2',
                  'JPMQB_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.JPMQB_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPMQS_B2', 'V_TJ2', 'JPMQS_B2_WF','2#上次煤气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.JPMQS_B2,
                  'JPMQS_B2',
                  'V_TJ2',
                  'JPMQS_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.JPMQS_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPMQQ_B2', 'V_TJ2', 'JPMQQ_B2_WF','2#前次煤气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.JPMQQ_B2,
                  'JPMQQ_B2',
                  'V_TJ2',
                  'JPMQQ_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.JPMQQ_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPMQB_B3', 'V_TJ3', 'JPMQB_B3_WF','3#本次煤气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.JPMQB_B3,
                  'JPMQB_B3',
                  'V_TJ3',
                  'JPMQB_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.JPMQB_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPMQS_B3', 'V_TJ3', 'JPMQS_B3_WF','3#上次煤气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.JPMQS_B3,
                  'JPMQS_B3',
                  'V_TJ3',
                  'JPMQS_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.JPMQS_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPMQQ_B3', 'V_TJ3', 'JPMQQ_B3_WF','3#前次煤气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.JPMQQ_B3,
                  'JPMQQ_B3',
                  'V_TJ3',
                  'JPMQQ_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.JPMQQ_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPMQB_B4', 'V_TJ4', 'JPMQB_B4_WF','4#本次煤气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.JPMQB_B4,
                  'JPMQB_B4',
                  'V_TJ4',
                  'JPMQB_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.JPMQB_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPMQS_B4', 'V_TJ4', 'JPMQS_B4_WF','4#上次煤气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.JPMQS_B4,
                  'JPMQS_B4',
                  'V_TJ4',
                  'JPMQS_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.JPMQS_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPMQQ_B4', 'V_TJ4', 'JPMQQ_B4_WF','4#前次煤气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.JPMQQ_B4,
                  'JPMQQ_B4',
                  'V_TJ4',
                  'JPMQQ_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.JPMQQ_B4 }}
						</div>
					</div>
					<div class="el-contal5 fl">
						<div class="heightr2">空气压力(Kpa)</div>
						<div class="heightr2">平均值</div>
						<div class="heightr3" @click="toCompon(2, 'JPKQB_B1', 'V_TJ1', 'JPKQB_B1_WF','1#本次空气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.JPKQB_B1,
                  'JPKQB_B1',
                  'V_TJ1',
                  'JPKQB_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.JPKQB_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPKQS_B1', 'V_TJ1', 'JPKQS_B1_WF','1#上次空气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.JPKQS_B1,
                  'JPKQS_B1',
                  'V_TJ1',
                  'JPKQS_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.JPKQS_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPKQQ_B1', 'V_TJ1', 'JPKQQ_B1_WF','1#前次空气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.JPKQQ_B1,
                  'JPKQQ_B1',
                  'V_TJ1',
                  'JPKQQ_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.JPKQQ_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPKQB_B2', 'V_TJ2', 'JPKQB_B2_WF','2#本次空气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.JPKQB_B2,
                  'JPKQB_B2',
                  'V_TJ2',
                  'JPKQB_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.JPKQB_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPKQS_B2', 'V_TJ2', 'JPKQS_B2_WF','2#上次空气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.JPKQS_B2,
                  'JPKQS_B2',
                  'V_TJ2',
                  'JPKQS_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.JPKQS_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPKQQ_B2', 'V_TJ2', 'JPKQQ_B2_WF','2#前次空气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.JPKQQ_B2,
                  'JPKQQ_B2',
                  'V_TJ2',
                  'JPKQQ_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.JPKQQ_B2 }}
						</div>

						<div class="heightr3" @click="toCompon(2, 'JPKQB_B3', 'V_TJ3', 'JPKQB_B3_WF','3#本次空气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.JPKQB_B3,
                  'JPKQB_B3',
                  'V_TJ3',
                  'JPKQB_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.JPKQB_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPKQS_B3', 'V_TJ3', 'JPKQS_B3_WF','3#上次空气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.JPKQS_B3,
                  'JPKQS_B3',
                  'V_TJ3',
                  'JPKQS_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.JPKQS_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPKQQ_B3', 'V_TJ3', 'JPKQQ_B3_WF','3#前次空气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.JPKQQ_B3,
                  'JPKQQ_B3',
                  'V_TJ3',
                  'JPKQQ_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.JPKQQ_B3 }}
						</div>

						<div class="heightr3" @click="toCompon(2, 'JPKQB_B4', 'V_TJ4', 'JPKQB_B4_WF','4#本次空气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.JPKQB_B4,
                  'JPKQB_B4',
                  'V_TJ4',
                  'JPKQB_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.JPKQB_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPKQS_B4', 'V_TJ4', 'JPKQS_B4_WF','4#上次空气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.JPKQS_B4,
                  'JPKQS_B4',
                  'V_TJ4',
                  'JPKQS_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.JPKQS_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JPKQQ_B4', 'V_TJ4', 'JPKQQ_B4_WF','4#前次空气压力均值')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.JPKQQ_B4,
                  'JPKQQ_B4',
                  'V_TJ4',
                  'JPKQQ_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.JPKQQ_B4 }}
						</div>
					</div>
				</div>
			</div>
			<div class="table2 fl">
				<div style="height: 6vh">
					<div class="el-contal4 fl">
						<div class="heightr2">煤气流量（m³）</div>
						<div class="heightr2">平均值</div>
						<div class="heightr3" @click="toCompon(2, 'JFMQB1_B1', 'V_TJ1', 'JFMQB1_B1_WF','1#本次煤气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.JFMQB1_B1,
                  'JFMQB1_B1',
                  'V_TJ1',
                  'JFMQB1_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.JFMQB1_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMQS1_B1', 'V_TJ1', 'JFMQS1_B1_WF','1#上次煤气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.JFMQS1_B1,
                  'JFMQS1_B1',
                  'V_TJ1',
                  'JFMQS1_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.JFMQS1_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMQQ1_B1', 'V_TJ1', 'JFMQQ1_B1_WF','1#前次煤气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.JFMQQ1_B1,
                  'JFMQQ1_B1',
                  'V_TJ1',
                  'JFMQQ1_B1'
                )
              ">
							{{ infoList.V_TJ1.JFMQQ1_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMQB1_B2', 'V_TJ2', 'JFMQB1_B2_WF','2#本次煤气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.JFMQB1_B2,
                  'JFMQB1_B2',
                  'V_TJ2',
                  'JFMQB1_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.JFMQB1_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMQS1_B2', 'V_TJ2', 'JFMQS1_B2_WF','2#上次煤气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.JFMQS1_B2,
                  'JFMQS1_B2',
                  'V_TJ2',
                  'JFMQS1_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.JFMQS1_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMQQ1_B2', 'V_TJ2', 'JFMQQ1_B2_WF','2#前次煤气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.JFMQQ1_B2,
                  'JFMQQ1_B2',
                  'V_TJ2',
                  'JFMQQ1_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.JFMQQ1_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMQB1_B3', 'V_TJ3', 'JFMQB1_B3_WF','3#本次煤气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.JFMQB1_B3,
                  'JFMQB1_B3',
                  'V_TJ3',
                  'JFMQB1_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.JFMQB1_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMQS1_B3', 'V_TJ3', 'JFMQS1_B3_WF','3#上次煤气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.JFMQS1_B3,
                  'JFMQS1_B3',
                  'V_TJ3',
                  'JFMQS1_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.JFMQS1_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMQQ1_B3', 'V_TJ3', 'JFMQQ1_B3_WF','3#前次煤气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.JFMQQ1_B3,
                  'JFMQQ1_B3',
                  'V_TJ3',
                  'JFMQQ1_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.JFMQQ1_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMQB1_B4', 'V_TJ4', 'JFMQB1_B4_WF','4#本次煤气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.JFMQB1_B4,
                  'JFMQB1_B4',
                  'V_TJ4',
                  'JFMQB1_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.JFMQB1_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMQS1_B4', 'V_TJ4', 'JFMQS1_B4_WF','4#上次煤气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.JFMQS1_B4,
                  'JFMQS1_B4',
                  'V_TJ4',
                  'JFMQS1_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.JFMQS1_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMQQ1_B4', 'V_TJ4', 'JFMQQ1_B4_WF','4#前次煤气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.JFMQQ1_B4,
                  'JFMQQ1_B4',
                  'V_TJ4',
                  'JFMQQ1_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.JFMQQ1_B4 }}
						</div>
					</div>
					<div class="el-contal5 fl">
						<div class="heightr2">空气流量（m³）</div>
						<div class="heightr2">平均值</div>
						<div class="heightr3" @click="toCompon(2, 'JFKQB1_B1', 'V_TJ1', 'JFKQB1_B1_WF','1#本次空气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.JFKQB1_B1,
                  'JFKQB1_B1',
                  'V_TJ1',
                  'JFKQB1_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.JFKQB1_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFKQS1_B1', 'V_TJ1', 'JFKQS1_B1_WF','1#上次空气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.JFKQS1_B1,
                  'JFKQS1_B1',
                  'V_TJ1',
                  'JFKQS1_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.JFKQS1_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFKQQ1_B1', 'V_TJ1', 'JFKQQ1_B1_WF','1#前次空气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.JFKQQ1_B1,
                  'JFKQQ1_B1',
                  'V_TJ1',
                  'JFKQQ1_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.JFKQQ1_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFKQB1_B2', 'V_TJ2', 'JFKQB1_B2_WF','2#本次空气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.JFKQB1_B2,
                  'JFKQB1_B2',
                  'V_TJ2',
                  'JFKQB1_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.JFKQB1_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFKQS1_B2', 'V_TJ2', 'JFKQS1_B2_WF','2#上次空气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.JFKQS1_B2,
                  'JFKQS1_B2',
                  'V_TJ2',
                  'JFKQS1_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.JFKQS1_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFKQQ1_B2', 'V_TJ2', 'JFKQQ1_B2_WF','2#前次空气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ2.JFKQQ1_B2,
                  'JFKQQ1_B2',
                  'V_TJ2',
                  'JFKQQ1_B2_WF'
                )
              ">
							{{ infoList.V_TJ2.JFKQQ1_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFKQB1_B3', 'V_TJ3', 'JFKQB1_B3_WF','3#本次空气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.JFKQB1_B3,
                  'JFKQB1_B3',
                  'V_TJ3',
                  'JFKQB1_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.JFKQB1_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFKQS1_B3', 'V_TJ3', 'JFKQS1_B3_WF','3#上次空气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.JFKQS1_B3,
                  'JFKQS1_B3',
                  'V_TJ3',
                  'JFKQS1_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.JFKQS1_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFKQQ1_B3', 'V_TJ3', 'JFKQQ1_B3_WF','3#前次空气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ3.JFKQQ1_B3,
                  'JFKQQ1_B3',
                  'V_TJ3',
                  'JFKQQ1_B3_WF'
                )
              ">
							{{ infoList.V_TJ3.JFKQQ1_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFKQB1_B4', 'V_TJ4', 'JFKQB1_B4_WF','4#本次空气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.JFKQB1_B4,
                  'JFKQB1_B4',
                  'V_TJ4',
                  'JFKQB1_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.JFKQB1_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFKQS1_B4', 'V_TJ4', 'JFKQS1_B4_WF','4#上次空气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.JFKQS1_B4,
                  'JFKQS1_B4',
                  'V_TJ4',
                  'JFKQS1_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.JFKQS1_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFKQQ1_B4', 'V_TJ4', 'JFKQQ1_B4_WF','4#前次空气流量均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ4.JFKQQ1_B4,
                  'JFKQQ1_B4',
                  'V_TJ4',
                  'JFKQQ1_B4_WF'
                )
              ">
							{{ infoList.V_TJ4.JFKQQ1_B4 }}
						</div>
					</div>
				</div>
			</div>
			<div class="table2 fl">
				<div style="height: 6vh">
					<div class="el-contal4 fl">
						<div class="heightr2">煤气阀位(%)</div>
						<div class="heightr2">平均值</div>
						<div class="heightr3" @click="toCompon(2, 'JFVMQB1_B1', 'V_TJ1', 'JFVMQB1_B1_WF','1#本次煤气阀位均值1')"
							@dblclick="
                Cclick(
                  infoList.V_TJ1.JFVMQB1_B1,
                  'JFVMQB1_B1',
                  'V_TJ1',
                  'JFVMQB1_B1_WF'
                )
              ">
							{{ infoList.V_TJ1.JFVMQB1_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVMQS1_B1', 'V_TJ1', 'JFVMQS1_B1_WF','1#上次煤气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ1.JFVMQS1_B1,'JFVMQS1_B1', 'V_TJ1', 'JFVMQS1_B1_WF')">
							{{ infoList.V_TJ1.JFVMQS1_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVMQQ1_B1', 'V_TJ1', 'JFVMQQ1_B1_WF','1#前次煤气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ1.JFVMQQ1_B1,'JFVMQQ1_B1', 'V_TJ1', 'JFVMQQ1_B1_WF')">
							{{ infoList.V_TJ1.JFVMQQ1_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVMQB1_B2', 'V_TJ2', 'JFVMQB1_B2_WF','2#本次煤气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ2.JFVMQB1_B2,'JFVMQB1_B2', 'V_TJ2', 'JFVMQB1_B2_WF')">
							{{ infoList.V_TJ2.JFVMQB1_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVMQS1_B2', 'V_TJ2', 'JFVMQS1_B2_WF','2#上次煤气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ2.JFVMQS1_B2,'JFVMQS1_B2', 'V_TJ2', 'JFVMQS1_B2_WF')">
							{{ infoList.V_TJ2.JFVMQS1_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVMQQ1_B2', 'V_TJ2', 'JFVMQQ1_B2_WF','2#前次煤气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ2.JFVMQQ1_B2,'JFVMQQ1_B2', 'V_TJ2', 'JFVMQQ1_B2_WF')">
							{{ infoList.V_TJ2.JFVMQQ1_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVMQB1_B3', 'V_TJ3', 'JFVMQB1_B3_WF','3#本次煤气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ3.JFVMQB1_B3,'JFVMQB1_B3', 'V_TJ3', 'JFVMQB1_B3_WF')">
							{{ infoList.V_TJ3.JFVMQB1_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVMQS1_B3', 'V_TJ3', 'JFVMQS1_B3_WF','3#上次煤气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ3.JFVMQS1_B3,'JFVMQS1_B3', 'V_TJ3', 'JFVMQS1_B3_WF')">
							{{ infoList.V_TJ3.JFVMQS1_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVMQQ1_B3', 'V_TJ3', 'JFVMQQ1_B3_WF','3#前次煤气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ3.JFVMQQ1_B3,'JFVMQQ1_B3', 'V_TJ3', 'JFVMQQ1_B3_WF')">
							{{ infoList.V_TJ3.JFVMQQ1_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVMQB1_B4', 'V_TJ4', 'JFVMQB1_B4_WF','4#本次煤气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ4.JFVMQB1_B4,'JFVMQB1_B4', 'V_TJ4', 'JFVMQB1_B4_WF')">
							{{ infoList.V_TJ4.JFVMQB1_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVMQS1_B4', 'V_TJ4', 'JFVMQS1_B4_WF','4#上次煤气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ4.JFVMQS1_B4,'JFVMQS1_B4', 'V_TJ4', 'JFVMQS1_B4_WF')">
							{{ infoList.V_TJ4.JFVMQS1_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVMQQ1_B4', 'V_TJ4', 'JFVMQQ1_B4_WF','4#前次煤气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ4.JFVMQQ1_B4,'JFVMQQ1_B4', 'V_TJ4', 'JFVMQQ1_B4')">
							{{ infoList.V_TJ4.JFVMQQ1_B4 }}
						</div>
					</div>
					<div class="el-contal5 fl">
						<div class="heightr2">空气阀位(%)</div>
						<div class="heightr2">平均值</div>
						<div class="heightr3" @click="toCompon(2, 'JFVKQB1_B1', 'V_TJ1', 'JFVKQB1_B1_WF','1#本次空气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ1.JFVKQB1_B1,'JFVKQB1_B1', 'V_TJ1', 'JFVKQB1_B1_WF')">
							{{ infoList.V_TJ1.JFVKQB1_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVKQS1_B1', 'V_TJ1', 'JFVKQS1_B1_WF','1#上次空气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ1.JFVKQS1_B1,'JFVKQS1_B1', 'V_TJ1', 'JFVKQS1_B1_WF')">
							{{ infoList.V_TJ1.JFVKQS1_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVKQQ1_B1', 'V_TJ1', 'JFVKQQ1_B1_WF','1#前次空气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ1.JFVKQQ1_B1,'JFVKQQ1_B1', 'V_TJ1', 'JFVKQQ1_B1_WF')">
							{{ infoList.V_TJ1.JFVKQQ1_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVKQB1_B2', 'V_TJ2', 'JFVKQB1_B2_WF','2#本次空气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ2.JFVKQB1_B2,'JFVKQB1_B2', 'V_TJ2', 'JFVKQB1_B2_WF')">
							{{ infoList.V_TJ2.JFVKQB1_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVKQS1_B2', 'V_TJ2', 'JFVKQS1_B2_WF','2#上次空气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ2.JFVKQS1_B2,'JFVKQS1_B2', 'V_TJ2', 'JFVKQS1_B2_WF')">
							{{ infoList.V_TJ2.JFVKQS1_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVKQQ1_B2', 'V_TJ2', 'JFVKQQ1_B2_WF','2#前次空气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ2.JFVKQQ1_B2,'JFVKQQ1_B2', 'V_TJ2', 'JFVKQQ1_B2_WF')">
							{{ infoList.V_TJ2.JFVKQQ1_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVKQB1_B3', 'V_TJ3', 'JFVKQB1_B3_WF','3#本次空气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ3.JFVKQB1_B3,'JFVKQB1_B3', 'V_TJ3', 'JFVKQB1_B3_WF')">
							{{ infoList.V_TJ3.JFVKQB1_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVKQS1_B3', 'V_TJ3', 'JFVKQS1_B3_WF','3#上次空气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ3.JFVKQS1_B3,'JFVKQS1_B3', 'V_TJ3', 'JFVKQS1_B3_WF')">
							{{ infoList.V_TJ3.JFVKQS1_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVKQQ1_B3', 'V_TJ3', 'JFVKQQ1_B3_WF','3#前次空气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ3.JFVKQQ1_B3,'JFVKQQ1_B3', 'V_TJ3', 'JFVKQQ1_B3_WF')">
							{{ infoList.V_TJ3.JFVKQQ1_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVKQB1_B4', 'V_TJ4', 'JFVKQB1_B4_WF','4#本次空气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ4.JFVKQB1_B4,'JFVKQB1_B4', 'V_TJ4', 'JFVKQB1_B4_WF')">
							{{ infoList.V_TJ4.JFVKQB1_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVKQS1_B4', 'V_TJ4', 'JFVKQS1_B4_WF','4#上次空气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ4.JFVKQS1_B4,'JFVKQS1_B4', 'V_TJ4', 'JFVKQS1_B4_WF')">
							{{ infoList.V_TJ4.JFVKQS1_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFVKQQ1_B4', 'V_TJ4', 'JFVKQQ1_B4_WF','4#前次空气阀位均值1')"
							@dblclick="Cclick(infoList.V_TJ4.JFVKQQ1_B4,'JFVKQQ1_B4', 'V_TJ4', 'JFVKQQ1_B4')">
							{{ infoList.V_TJ4.JFVKQQ1_B4 }}
						</div>
					</div>
				</div>
			</div>
			<div class="table3 fl">
				<div style="height: 6vh">
					<div class="el-contal4 fl">
						<div class="heightr2">实际风煤比(%)</div>
						<div class="heightr2">平均值</div>
						<div class="heightr3" @click="toCompon(2, 'JFMBB1_B1', 'V_TJ1', 'JFMBB1_B1_WF','1#本次风煤比均值1')"
							@dblclick="Cclick(infoList.V_TJ1.JFMBB1_B1,'JFMBB1_B1', 'V_TJ1', 'JFMBB1_B1_WF')">
							{{ infoList.V_TJ1.JFMBB1_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMBS1_B1', 'V_TJ1', 'JFMBS1_B1_WF','1#上次风煤比均值1')"
							@dblclick="Cclick(infoList.V_TJ1.JFMBS1_B1,'JFMBS1_B1', 'V_TJ1', 'JFMBS1_B1_WF')">
							{{ infoList.V_TJ1.JFMBS1_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMBQ1_B1', 'V_TJ1', 'JFMBQ1_B1_WF','1#前次风煤比均值1')"
							@dblclick="Cclick(infoList.V_TJ1.JFMBQ1_B1,'JFMBQ1_B1', 'V_TJ1', 'JFMBQ1_B1_WF')">
							{{ infoList.V_TJ1.JFMBQ1_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMBB1_B2', 'V_TJ2', 'JFMBB1_B2_WF','2#本次风煤比均值1')"
							@dblclick="Cclick(infoList.V_TJ2.JFMBB1_B2,'JFMBB1_B2', 'V_TJ2', 'JFMBB1_B2_WF')">
							{{ infoList.V_TJ2.JFMBB1_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMBS1_B2', 'V_TJ2', 'JFMBS1_B2_WF','2#上次风煤比均值1')"
							@dblclick="Cclick(infoList.V_TJ2.JFMBS1_B2,'JFMBS1_B2', 'V_TJ2', 'JFMBS1_B2_WF')">
							{{ infoList.V_TJ2.JFMBS1_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMBQ1_B2', 'V_TJ2', 'JFMBQ1_B2_WF','2#前次风煤比均值1')"
							@dblclick="Cclick(infoList.V_TJ2.JFMBQ1_B2,'JFMBQ1_B2', 'V_TJ2', 'JFMBQ1_B2_WF')">
							{{ infoList.V_TJ2.JFMBQ1_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMBB1_B3', 'V_TJ3', 'JFMBB1_B3_WF','3#本次风煤比均值1')"
							@dblclick="Cclick(infoList.V_TJ3.JFMBB1_B3,'JFMBB1_B3', 'V_TJ3', 'JFMBB1_B3_WF')">
							{{ infoList.V_TJ3.JFMBB1_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMBS1_B3', 'V_TJ3', 'JFMBS1_B3_WF','3#上次风煤比均值1')"
							@dblclick="Cclick(infoList.V_TJ3.JFMBS1_B3,'JFMBS1_B3', 'V_TJ3', 'JFMBS1_B3_WF')">
							{{ infoList.V_TJ3.JFMBS1_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMBQ1_B3', 'V_TJ3', 'JFMBQ1_B3_WF','3#前次风煤比均值1')"
							@dblclick="Cclick(infoList.V_TJ3.JFMBQ1_B3,'JFMBQ1_B3', 'V_TJ3', 'JFMBQ1_B3_WF')">
							{{ infoList.V_TJ3.JFMBQ1_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMBB1_B4', 'V_TJ4', 'JFMBB1_B4_WF','4#本次风煤比均值1')"
							@dblclick="Cclick(infoList.V_TJ4.JFMBB1_B4,'JFMBB1_B4', 'V_TJ4', 'JFMBB1_B4_WF')">
							{{ infoList.V_TJ4.JFMBB1_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMBS1_B4', 'V_TJ4', 'JFMBS1_B4_WF','4#上次风煤比均值1')"
							@dblclick="Cclick(infoList.V_TJ4.JFMBS1_B4,'JFMBS1_B4', 'V_TJ4', 'JFMBS1_B4_WF')">
							{{ infoList.V_TJ4.JFMBS1_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFMBQ1_B4', 'V_TJ4', 'JFMBQ1_B4_WF','4#前次风煤比均值1')"
							@dblclick="Cclick(infoList.V_TJ4.JFMBQ1_B4,'JFMBQ1_B4', 'V_TJ4', 'JFMBQ1_B4_WF')">
							{{ infoList.V_TJ4.JFMBQ1_B4 }}
						</div>
					</div>
					<div class="el-contal5 fl">
						<div class="heightr2">煤气累计</div>
						<div class="heightr2">（m³）</div>
						<div class="heightr3" @click="toCompon(2, 'MQLJB_B1', 'V_TJ1', 'MQLJB_B1_WF','1#本次煤气累积')"
							@dblclick="Cclick(infoList.V_TJ1.MQLJB_B1,'MQLJB_B1', 'V_TJ1', 'MQLJB_B1_WF')">
							{{ infoList.V_TJ1.MQLJB_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'MQLJS_B1', 'V_TJ1', 'MQLJS_B1_WF','1#上次煤气累积')"
							@dblclick="Cclick(infoList.V_TJ1.MQLJS_B1,'MQLJS_B1', 'V_TJ1', 'MQLJS_B1_WF')">
							{{ infoList.V_TJ1.MQLJS_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'MQLJQ_B1', 'V_TJ1', 'MQLJQ_B1_WF','1#前次煤气累积')"
							@dblclick="Cclick(infoList.V_TJ1.MQLJQ_B1,'MQLJQ_B1', 'V_TJ1', 'MQLJQ_B1_WF')">
							{{ infoList.V_TJ1.MQLJQ_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'MQLJB_B2', 'V_TJ2', 'MQLJB_B2_WF','2#本次煤气累积')"
							@dblclick="Cclick(infoList.V_TJ2.MQLJB_B2,'MQLJB_B2', 'V_TJ2', 'MQLJB_B2_WF')">
							{{ infoList.V_TJ2.MQLJB_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'MQLJS_B2', 'V_TJ2', 'MQLJS_B2_WF','2#上次煤气累积')"
							@dblclick="Cclick(infoList.V_TJ2.MQLJS_B2,'MQLJS_B2', 'V_TJ2', 'MQLJS_B2_WF')">
							{{ infoList.V_TJ2.MQLJS_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'MQLJQ_B2', 'V_TJ2', 'MQLJQ_B2_WF','2#前次煤气累积')"
							@dblclick="Cclick(infoList.V_TJ2.MQLJQ_B2,'MQLJQ_B2', 'V_TJ2', 'MQLJQ_B2_WF')">
							{{ infoList.V_TJ2.MQLJQ_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'MQLJB_B3', 'V_TJ3', 'MQLJB_B3_WF','3#本次煤气累积')"
							@dblclick="Cclick(infoList.V_TJ3.MQLJB_B3,'MQLJB_B3', 'V_TJ3', 'MQLJB_B3_WF')">
							{{ infoList.V_TJ3.MQLJB_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'MQLJS_B3', 'V_TJ3', 'MQLJS_B3_WF','3#上次煤气累积')"
							@dblclick="Cclick(infoList.V_TJ3.MQLJS_B3,'MQLJS_B3', 'V_TJ3', 'MQLJS_B3_WF')">
							{{ infoList.V_TJ3.MQLJS_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'MQLJQ_B3', 'V_TJ3', 'MQLJQ_B3_WF','3#前次煤气累积')"
							@dblclick="Cclick(infoList.V_TJ3.MQLJQ_B3,'MQLJQ_B3', 'V_TJ3', 'MQLJQ_B3_WF')">
							{{ infoList.V_TJ3.MQLJQ_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'MQLJB_B4', 'V_TJ4', 'MQLJB_B4_WF','4#本次煤气累积')"
							@dblclick="Cclick(infoList.V_TJ4.MQLJB_B4,'MQLJB_B4', 'V_TJ4', 'MQLJB_B4_WF')">
							{{ infoList.V_TJ4.MQLJB_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'MQLJS_B4', 'V_TJ4', 'MQLJS_B4_WF','4#上次煤气累积')"
							@dblclick="Cclick(infoList.V_TJ4.MQLJS_B4,'MQLJS_B4', 'V_TJ4', 'MQLJS_B4_WF')">
							{{ infoList.V_TJ4.MQLJS_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'MQLJQ_B4', 'V_TJ4', 'MQLJQ_B4_WF','4#前次煤气累积')"
							@dblclick="Cclick(infoList.V_TJ4.MQLJQ_B4,'MQLJQ_B4', 'V_TJ4', 'MQLJQ_B4_WF')">
							{{ infoList.V_TJ4.MQLJQ_B4 }}
						</div>
					</div>
					<div class="el-contal6 fl">
						<div class="heightr2">烟道温升斜率</div>
						<div class="heightr2">（℃/min）</div>
						<div class="heightr3" @click="toCompon(2, 'JFQXLB_B1', 'V_TJ1', 'JFQXLB_B1_WF','1#本次废气斜率')"
							@dblclick="Cclick(infoList.V_TJ1.JFQXLB_B1,'JFQXLB_B1', 'V_TJ1', 'JFQXLB_B1_WF')">
							{{ infoList.V_TJ1.JFQXLB_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFQXLS_B1', 'V_TJ1', 'JFQXLS_B1_WF','1#上次废气斜率')"
							@dblclick="Cclick(infoList.V_TJ1.JFQXLS_B1,'JFQXLS_B1', 'V_TJ1', 'JFQXLS_B1_WF')">
							{{ infoList.V_TJ1.JFQXLS_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFQXLQ_B1', 'V_TJ1', 'JFQXLQ_B1_WF','1#前次废气斜率')"
							@dblclick="Cclick(infoList.V_TJ1.JFQXLQ_B1,'JFQXLQ_B1', 'V_TJ1', 'JFQXLQ_B1_WF')">
							{{ infoList.V_TJ1.JFQXLQ_B1 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFQXLB_B2', 'V_TJ2', 'JFQXLB_B2_WF','2#本次废气斜率')"
							@dblclick="Cclick(infoList.V_TJ2.JFQXLB_B2,'JFQXLB_B2', 'V_TJ2', 'JFQXLB_B2_WF')">
							{{ infoList.V_TJ2.JFQXLB_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFQXLS_B2', 'V_TJ2', 'JFQXLS_B2_WF','2#上次废气斜率')"
							@dblclick="Cclick(infoList.V_TJ2.JFQXLS_B2,'JFQXLS_B2', 'V_TJ2', 'JFQXLS_B2_WF')">
							{{ infoList.V_TJ2.JFQXLS_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFQXLQ_B2', 'V_TJ2', 'JFQXLQ_B2_WF','2#前次废气斜率')"
							@dblclick="Cclick(infoList.V_TJ2.JFQXLQ_B2,'JFQXLQ_B2', 'V_TJ2', 'JFQXLQ_B2_WF')">
							{{ infoList.V_TJ2.JFQXLQ_B2 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFQXLB_B3', 'V_TJ3', 'JFQXLB_B3_WF','3#本次废气斜率')">
							{{ infoList.V_TJ3.JFQXLB_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFQXLS_B3', 'V_TJ3', 'JFQXLS_B3_WF','3#上次废气斜率')"
							@dblclick="Cclick(infoList.V_TJ3.JFQXLS_B3,'JFQXLS_B3', 'V_TJ3', 'JFQXLS_B3_WF')">
							{{ infoList.V_TJ3.JFQXLS_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFQXLQ_B3', 'V_TJ3', 'JFQXLQ_B3_WF','3#前次废气斜率')"
							@dblclick="Cclick(infoList.V_TJ3.JFQXLQ_B3,'JFQXLQ_B3', 'V_TJ3', 'JFQXLQ_B3_WF')">
							{{ infoList.V_TJ3.JFQXLQ_B3 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFQXLB_B4', 'V_TJ4', 'JFQXLB_B4_WF','4#本次废气斜率')"
							@dblclick="Cclick(infoList.V_TJ4.JFQXLB_B4,'JFQXLB_B4', 'V_TJ4', 'JFQXLB_B4_WF')">
							{{ infoList.V_TJ4.JFQXLB_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFQXLS_B4', 'V_TJ4', 'JFQXLS_B4_WF','4#上次废气斜率')"
							@dblclick="Cclick(infoList.V_TJ4.JFQXLS_B4,'JFQXLS_B4', 'V_TJ4', 'JFQXLS_B4_WF')">
							{{ infoList.V_TJ4.JFQXLS_B4 }}
						</div>
						<div class="heightr3" @click="toCompon(2, 'JFQXLQ_B4', 'V_TJ4', 'JFQXLQ_B4_WF','4#前次废气斜率')"
							@dblclick="Cclick(infoList.V_TJ4.JFQXLQ_B4,'JFQXLQ_B4', 'V_TJ4', 'JFQXLQ_B4_WF')">
							{{ infoList.V_TJ4.JFQXLQ_B4 }}
						</div>
					</div>
				</div>
			</div>
		</div>
		 <Historical v-if="isHshow" @sendStatus="isClose1" :historyname="historyname" :node="node" :Lkname="Lkname"
			:chName="chName" :infoList="infoList"></Historical>
	</div>
</template>
<script>
	import Historical from "@/components/Historical.vue"; //历史趋势
	export default {
		name: "Evaluate",
		components: {
			Historical
		},
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {}; // 默认值
				},
			},
		},
		data: () => {
			return {
				chName: '',
				isComShow: false,
				isHshow: false,
				isClose: false,
				historyname: "",
				node: "",
				Lkname: "",
				spotArr: [],
				grouptime: null,
				// data11: ["10.00", "1", "2", "3"],
				// data22: ["12","13","15","13"],
				// data33: ["18","14","13","12"],
			};
		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		mounted() {
			this.drawLine();
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ?
				JSON.parse(localStorage.getItem("spotArr")) : [];
			// this.level =
		},
		methods: {
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name,
				};
				this.spotArr.push(spojobj);
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll();
							map.set(item.name, item);
							this.$message.success("已添加");
						} else {
							this.$message.closeAll();
							this.$message.error("已添加到变量池里，切勿重复添加");
						}
					}

					return [...map.values()];
				};
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
			},
			// 数字的处理，小数点仅保留一位，或者整数的情况下补位
			keepOneDecimalFull(num) {
				var str = Number((num * 1000) / 1000).toFixed(3).slice(0, -2);
				return str;
			},
			drawLine() {
				this.data11 = [
					this.keepOneDecimalFull(this.infoList.V_TJ1.MQLJB2_B1),
					this.keepOneDecimalFull(this.infoList.V_TJ2.MQLJB2_B2),
					this.keepOneDecimalFull(this.infoList.V_TJ3.MQLJB2_B3),
					this.keepOneDecimalFull(this.infoList.V_TJ4.MQLJB2_B4),
				];
				this.data22 = [
					this.keepOneDecimalFull(this.infoList.V_TJ1.MQLJS2_B1),
					this.keepOneDecimalFull(this.infoList.V_TJ2.MQLJS2_B2),
					this.keepOneDecimalFull(this.infoList.V_TJ3.MQLJS2_B3),
					this.keepOneDecimalFull(this.infoList.V_TJ4.MQLJS2_B4),
				];
				this.data33 = [
					this.keepOneDecimalFull(this.infoList.V_TJ1.MQLJQ2_B1),
					this.keepOneDecimalFull(this.infoList.V_TJ2.MQLJQ2_B2),
					this.keepOneDecimalFull(this.infoList.V_TJ3.MQLJQ2_B3),
					this.keepOneDecimalFull(this.infoList.V_TJ4.MQLJQ2_B4),
				];
				// 基于准备好的dom，初始化echarts实例
				let myChart = this.$echarts.init(document.getElementById("myChart"));

				// 绘制图表
				myChart.setOption({
					title: {
						text: "",
						subtext: "",
					},

					color: ["#31b4f3", "#8be777", "#f7953d"],
					legend: {
						data: ["当前", "上次", "前次"],
						textStyle: {
							color: "#fff",
						},
					},
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "shadow",
							crossStyle: {
								color: "#999",
							},
						},

						position: function(pt) {
							//提示框的位置
							return [pt[0], 30];
						},
					},
					calculable: false,
					xAxis: [{
						type: "category",
						axisLine: {
							show: true,
							lineStyle: {
								color: "#fff",
							},
						},
						data: ["1#热风炉", "2#热风炉", "3#热风炉", "4#热风炉"],
					}, ],
					yAxis: [{
						type: "value",
						max: 100,
						show: true,
						axisLabel: {
							formatter: "{value}%"
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#fff",
							},
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false
						},
					}, ],
					series: [{
							name: "当前",
							type: "bar",
							data: this.data11,
							itemStyle: {
								normal: {
									label: {
										show: true,
										position: "top",
										width: 1,
										textStyle: {
											color: "#31b4f3",
											fontSize: 9,
										},
									},
								},
							},
						},
						{
							name: "上次",
							type: "bar",
							data: this.data22,
							itemStyle: {
								normal: {
									label: {
										show: true,
										position: "top",
										textStyle: {
											color: "#8be777",
											fontSize: 9,
										},
									},
								},
							},
						},
						{
							name: "前次",
							type: "bar",
							data: this.data33,
							itemStyle: {
								normal: {
									label: {
										show: true,
										position: "top",
										textStyle: {
											color: "#f7953d",
											fontSize: 9,
										},
									},
								},
							},
						},
					],
				});
				myChart.on("click", (params) => {
					let index = params.dataIndex + 1;
					//  this.infoList.V_TJ1.MQLJB2_B1
					switch (params.seriesName) {
						case "当前":
							return this.toCompon(
								2,
								"MQLJB2_B" + index,
								"V_TJ" + index,
								"MQLJB2_B" + index + "_WF"
							);
						case "上次":
							return this.toCompon(
								2,
								"MQLJS2_B" + index,
								"V_TJ" + index,
								"MQLJS2_B" + index + "_WF"
							);
						case "前次":
							return this.toCompon(
								2,
								"MQLJQ2_B" + index,
								"V_TJ" + index,
								"MQLQJ2_B" + index + "_WF"
							);
					}
				});
			},


			closeCompon() {
				this.$emit("sendStatus", "Evaluate", false);
			},
			isClose1() {
				this.isHshow = false;
			},
			toCompon(key, name, name2, name3, name4) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return (this.isMshow = true);
						case 1:
							return (this.isFshow = true);
						case 2:
							return (this.isHshow = true, this.chName = name4);
					}
				}, 300);
			},
		},
	};
</script>
<style lang="scss" scoped>
	.Evaluate {
		width: 96vw;
		height: 100%;
		margin-left: 1.5vw;
		//  background: #e9f7ff;
		background-image: linear-gradient(#0e3449 60%, #0e3146 61%, #0c2a38 100%),
			linear-gradient(#0e3449, #0e3449);
		background-blend-mode: normal, normal;
		overflow: hidden;
		//  cursor:move;
		border-radius: 5px;

		.ziti {
			font-size: 1.6vh;
		}

		.zhuangshi1 {
			width: 305px;
			height: 26px;
			background-color: #0ef7ff;
			opacity: 0.66;
		}

		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.title-hang {
			height: 5vh;
			width: 96vw;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
		}

		.conter1 {
			margin-left: 1vw;
			width: 17vw;
			height: 29vh;
			line-height: 29vh;
			background-image: url("~@/assets/images/rfl_evaluate01.png");
			background-size: 100% 100%;
		}

		.conter {
			height: 29vh;
			width: 100%;
			margin-top: 3vh;
		}

		.conter1 {
			margin-left: 1vw;
			width: 17vw;
			height: 29vh;
			line-height: 29vh;
			background-image: url("~@/assets/images/rfl_evaluate01.png");
			background-size: 100% 100%;
		}

		.conter2 {
			margin-left: 1vw;
			width: 29vw;
			height: 29vh;
			// line-height: 29vh;
			background-image: url("~@/assets/images/rfl_evaluate03.png");
			background-size: 100% 100%;
		}

		.title1 {
			width: 8vw;
			height: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #ffffff;
			margin-top: 1vh;
			margin-left: 1vw;
		}

		.divcei {
			margin-top: 3vh;
			margin-left: 4vw;
			width: 9vw;
			height: 20vh;
			line-height: 20vh;
			background-image: url("~@/assets/images/rfl_evaluate02.png");
			background-size: 100% 100%;
			font-size: 2vw;
			font-weight: bold;
			font-stretch: normal;
			letter-spacing: 0vw;
			color: #ffffff;
			text-align: center;
		}

		.divjjx {
			margin-top: 3vh;
			margin-left: 4vw;
			width: 9vw;
			height: 26vh;
			line-height: 26vh;
			font-size: 1.6vw;
			font-weight: bold;
			font-stretch: normal;
			letter-spacing: 0vw;
			color: #ffffff;
			text-align: center;
		}

		.zhuangshizb {
			width: 14vw;
			height: 2vh;
			background-image: url("~@/assets/images/rfl_evaluate06.png");
			background-size: 100% 100%;
			margin-left: 3.5vw;
			margin-top: 1.6vh;
		}

		.zhuangshizj {
			width: 26vw;
			height: 4vh;
			line-height: 4vh;
			margin-top: 1vh;
			text-align: center;
			background-image: url("~@/assets/images/rfl_evaluate05.png");
			background-size: 100% 100%;
			margin-left: 5vw;
			font-family: MicrosoftYaHei;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vh;
			color: #0ef7ff;
		}

		.zhuangshiyb {
			width: 14vw;
			height: 2vh;
			background-image: url("~@/assets/images/rfl_evaluate04.png");
			background-size: 100% 100%;
			margin-left: 5vw;
			margin-top: 1.6vh;
		}

		.divzkl {
			width: 30vw;
			height: 30vh;
		}

		.divwdx {
			width: 30vw;
			height: 29vh;
			// border: 1px solid, color rgb(246, 14, 14);
			color: #c5c5c5;
		}

		.hang1 {
			margin-top: 1vh;
			height: 1vh;
			width: 30vw;
			font-size: 2vh;
		}

		.hang2 {
			margin-top: 3vh;
			width: 30vw;
			height: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #ffffff;
		}

		.hang3 {
			height: 3.4vh;
			width: 30vw;
			font-family: PingFang-SC-Regular;
			font-size: 1.6vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 4vh;
			letter-spacing: 0vh;
			color: #ffffff;
		}

		.row1 {
			width: 4vw;
			height: 3vh;
			text-align: center;
		}

		.row3 {
			width: 0.1vh;
			height: 3vh;
			line-height: 3vh;
			background-image: linear-gradient(0deg, #26a6f0 0%, #40c9f7 100%);
		}

		.row2 {
			width: 6vw;
			height: 3vh;
			text-align: center;
			cursor: pointer;
		}

		.conter11 {
			margin-top: 2vh;
			width: 96vw;
			height: 42vh;
			// border:1px solid #0ef7ff;
		}

		.table1 {
			width: 25vw;
			height: 42.3vh;
			border: 1px solid #00e5ff50;
			font-size: 1.6vh;
			// opacity: 0.5;
		}

		.table2 {
			margin-left: 0.5vw;
			width: 15vw;
			height: 42.3vh;
			border: 1px solid #00e5ff50;
			// opacity: 0.5;
		}

		.table3 {
			margin-left: 0.5vw;
			width: 22.5vw;
			height: 42.3vh;
			border: 1px solid #00e5ff50;
			// opacity: 0.5;
		}

		.el-contal1 {
			width: 9.05vw;
			height: 6vh;
			line-height: 9vh;
			font-size: 1.6vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 3vw;
			letter-spacing: 0vw;
			color: #6acafb;
			text-align: center;
		}

		.el-contal2 {
			width: 10.05vw;
			height: 6vh;
			// line-height: 6vh;
			font-size: 1.6vh;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;
			color: #6acafb;
			text-align: center;
			border-left: 1px solid #00e5ff50;
		}

		.el-contal3 {
			width: 5vw;
			height: 6vh;
			font-size: 1.6vh;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;
			color: #6acafb;
			text-align: center;
			border-left: 1px solid #00e5ff50;
		}

		.el-contal4 {
			width: 7.5vw;
			height: 42.3vh;
			font-size: 1.6vh;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;

			text-align: center;
			border-right: 1px solid #00e5ff50;
		}

		.el-contal5 {
			width: 7.39vw;
			height: 42.3vh;
			font-size: 1.6vh;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;

			text-align: center;
			// border-right: 1px solid #00e5ff50;
		}

		.el-contal6 {
			width: 7.39vw;
			height: 42.3vh;
			font-size: 1.6vh;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;

			text-align: center;
			border-left: 1px solid #00e5ff50;
		}

		.heightr {
			height: 3vh;
			width: 10vw;
			text-align: center;
		}

		.heightr1 {
			height: 3vh;
			width: 6vw;
			text-align: center;
		}

		.heightr2 {
			height: 3vh;
			width: 7.5vw;
			text-align: center;
			color: #6acafb;
		}

		.heightr3 {
			line-height: 2.95vh;
			width: 7.5vw;
			text-align: center;
			border-top: 1px solid #00e5ff50;
			color: #fff;
			cursor: pointer;
		}

		.heightw {
			height: 3vh;
			width: 5vw;
			text-align: center;
		}

		.div2 {
			width: 25vw;
			height: 9vh;
			border-top: 1px solid #00e5ff50;
			color: #ffffff;
		}

		.div2-1 {
			width: 5vw;
			height: 9vh;
			font-family: PingFang-SC-Regular;
			font-size: 1.6vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 9vh;
			letter-spacing: 0vh;
			color: #6acafb;
			text-align: center;
		}

		.div2-2 {
			width: 4vw;
			height: 9vh;
			font-family: PingFang-SC-Regular;
			font-size: 1.6vh;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vh;
			color: #6acafb;
			text-align: center;
			border-left: 1px solid #00e5ff50;
		}

		.div2-2-1 {
			width: 4vw;
			height: 3vh;

			text-align: center;
		}

		.div2-2-2 {
			width: 4vw;
			height: 3vh;
			border-top: 1px solid #00e5ff50;
		}

		.div2-3 {
			width: 5vw;
			height: 9vh;
			font-family: PingFang-SC-Regular;
			font-size: 1.6vh;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vh;
			color: #fff;
			text-align: center;
			border-left: 1px solid #00e5ff50;
		}

		.div2-3-1 {
			width: 5vw;
			height: 3vh;
			cursor: pointer;
			//  border-top: 1px solid #00e5ff50;
		}

		.div2-3-2 {
			width: 5vw;
			height: 3vh;
			border-top: 1px solid #00e5ff50;
			cursor: pointer;
		}

		.div2-3-3 {
			width: 5.8vw;
			height: 3vh;
			border-top: 1px solid #00e5ff50;
			cursor: pointer;
		}

		.div2-4 {
			width: 5vw;
			height: 9vh;
			font-family: PingFang-SC-Regular;
			font-size: 1.6vh;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vh;
			color: #6acafb;
			text-align: center;
			border-left: 1px solid #00e5ff50;
		}
	}
</style>
